<template>
  <div class="indexPage">
    <img src="../assets/kctx/banner.png" class="banner" />
    <div class="bannerSj"></div>
    <!-- 品牌介绍 -->
    <div class="orangeBlueBlock kctx">
      <div class="orangeBlueBlock1">品牌介绍</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="ppjs">
      <p>
        星耀启航教育科技有限公司隶属于北京星宇航发集团，集团专注于提供高端军用微小型涡喷发动机产品的领导者以及无人机整机系统服务的创新者。
      </p>
      <p>
        星宇航发旗下拥有陕西和南昌两个分公司，建有两个研发中心和两个生产基地，总占地面积约10000㎡。星耀启航旗下教育品牌—星航小院仔具备自主研发教学、教具能力，在航模、无人机领域中拥有最大的物联网平台。
      </p>
    </div>
    <div class="cards">
      <div class="card">
        <img class="img1" src="../assets/kctx/card1.png" />
        <img class="img2" src="../assets/kctx/cardName1.png" />
        <div class="blueBlock"></div>
        <div class="desc">基于公司军工技术优势</div>
      </div>
      <div class="card blue">
        <img class="img1" src="../assets/index/card2.png" />
        <img class="img2" src="../assets/kctx/cardName2.png" />
        <div class="blueBlock"></div>
        <div class="desc">吸收国际先进教学体系</div>
      </div>
      <div class="card">
        <img class="img1" src="../assets/kctx/card3.png" />
        <img class="img2" src="../assets/kctx/cardName3.png" />
        <div class="blueBlock"></div>
        <div class="desc">搭建多维课程体系</div>
      </div>
    </div>
    <!-- 多元化课程体系 -->
    <div class="orangeBlueBlock kctx">
      <div class="orangeBlueBlock1">多元化课程体系</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="dyhkcs">
      <div class="dyhkc">
        <div>
          <img class="cardImg" src="../assets/index/kctx1.png" />
          <div class="age">3~6岁</div>
        </div>
        <div>
          <img class="cardImg" src="../assets/index/kctx2.png" />
          <div class="age">6~12岁</div>
        </div>
      </div>
      <div class="dyhkc">
        <div>
          <img class="cardImg" src="../assets/index/kctx3.png" />
          <div class="age">冬夏令营</div>
        </div>
        <div>
          <img class="cardImg" src="../assets/index/kctx4.png" />
          <div class="age">低龄出国留学游学</div>
        </div>
      </div>
    </div>
    <!-- 课程导学图 -->
    <div class="orangeBlueBlock kctx">
      <div class="orangeBlueBlock1">课程导学图</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="kcdxt2"></div>
    <!-- 轻双师教学 -->
    <div class="orangeBlueBlock kctx">
      <div class="orangeBlueBlock1">轻双师教学</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="videoParent">
      <div class="videoBg1"></div>
      <div class="videoBg2"></div>
      <video
        class="video"
        controls
        controlslist="noplaybackrate nodownload"
        disablePictureInPicture
      >
        <source src="../assets/video.mp4" />
      </video>
      <!-- <div class="video"></div> -->
    </div>
    <!-- 产品资料部分展示 -->
    <div class="orangeBlueBlock kctx">
      <div class="orangeBlueBlock1">产品资料部分展示</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <!-- 教具展示 -->
    <div class="jczs">
      <div class="jczsTitle">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">教具展示</div>
      </div>
      <div class="jczsContent">
        <div>
          <img src="../assets/index/jjzs1.png" />
          <img src="../assets/index/jjzsFont1.png" />
        </div>
        <div>
          <img src="../assets/index/jjzs2.png" />
          <img src="../assets/index/jjzsFont2.png" />
        </div>
        <div>
          <img src="../assets/index/jjzs3.png" />
          <img src="../assets/index/jjzsFont1.png" />
        </div>
      </div>
    </div>
    <!-- 教材展示 -->
    <div class="jczs">
      <div class="jczsTitle">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">教材展示</div>
      </div>
      <div class="jczsContent">
        <div>
          <img src="../assets/index/jczs1.png" />
          <img src="../assets/index/jczsFont1.png" />
        </div>
        <div>
          <img src="../assets/index/jczs2.png" />
          <img src="../assets/index/jczsFont2.png" />
        </div>
        <div>
          <img src="../assets/index/jczs3.png" />
          <img src="../assets/index/jczsFont1.png" />
        </div>
      </div>
    </div>
    <!-- 教辅展示 -->
    <div class="jczs">
      <div class="jczsTitle">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">教辅展示</div>
      </div>
      <div class="jczsContent">
        <div>
          <img src="../assets/index/jfzs1.png" />
          <img src="../assets/index/jfzsFont1.png" />
        </div>
        <div>
          <img src="../assets/index/jfzs2.png" />
          <img src="../assets/index/jfzsFont2.png" />
        </div>
        <div>
          <img src="../assets/index/jfzs3.png" />
          <img src="../assets/index/jfzsFont1.png" />
        </div>
      </div>
    </div>
    <div class="bottomFont">
      星航小院仔感谢每一位用户，因为你们，星航小院仔的价值和理念得到了更广泛的传播。<br />
      只为当地孩子带来更好的航模、无人机编程课程！
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  components: {}
};
</script>
<style lang="less">
.indexPage {
  .banner {
    width: 100%;
    display: block;
  }
  .bannerSj {
    width: 0;
    height: 0;
    border-left: 85/19.2vw solid transparent;
    border-right: 85/19.2vw solid transparent;
    border-top: 85/19.2vw solid #0172bb;
    margin: 0 auto;
  }

  .orangeBlueBlock {
    width: 413/19.2vw;
    height: 114/19.2vw;
    position: relative;
    margin: 65/19.2vw auto 94/19.2vw;
    &.kctx {
      margin: 93/19.2vw auto 122/19.2vw;
    }
    > div {
      width: 400/19.2vw;
      height: 100/19.2vw;
    }
    .orangeBlueBlock1 {
      background: #ff9327;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(255, 255, 255);
      line-height: 40/19.2vw;
      font-style: italic;
      font-weight: bold;
    }
    .orangeBlueBlock2 {
      background: #0e7ab9;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }

  .ppjs {
    font-size: 32/19.2vw;
    font-family: 'Microsoft YaHei';
    color: rgb(38, 38, 38);
    line-height: 1.333;
    width: 1185/19.2vw;
    margin: 0 auto 120/19.2vw;
    text-indent: 64/19.2vw;
    text-align: left;
  }

  .blueBlock {
    width: 49/19.2vw;
    height: 6/19.2vw;
    background-color: #0b7bce;
  }
  .cards {
    width: 1305/19.2vw;
    display: flex;
    justify-content: space-between;
    margin: 173/19.2vw auto 0;
  }
  .card {
    width: 419/19.2vw;
    height: 694/19.2vw;
    background: #fff3ec;
    padding: 46/19.2vw 38/19.2vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.blue {
      background: #e8f2fa;
      position: relative;
      top: -65/19.2vw;
      .img1 {
        box-shadow: 0px 0px 10px 10px rgba(255, 229, 207, 0.85);
      }
    }
    .img1 {
      width: 337/19.2vw;
      height: 481/19.2vw;
      margin-bottom: 36/19.2vw;
      box-shadow: 0px 0px 10px 10px rgba(206, 228, 246, 0.85);
    }
    .img2 {
      height: 20/19.2vw;
      margin-bottom: 18/19.2vw;
    }
    .desc {
      margin-top: 18/19.2vw;
      font-size: 20/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(38, 38, 38);
      line-height: 1.2;
    }
  }
  .dyhkcs {
    width: 1500/19.2vw;
    margin: 0 auto;
    .dyhkc {
      display: flex;
      > div {
        margin: 10/19.2vw;
      }
      &:nth-child(2) {
        justify-content: flex-end;
      }
      .cardImg {
        width: 568/19.2vw;
        height: 378/19.2vw;
        box-shadow: 0 0 10px 10px rgba(255, 229, 207, 0.85);
        margin: 10/19.2vw;
      }
      .age {
        font-size: 24/19.2vw;
        font-family: 'Microsoft YaHei';
        font-weight: bold;
        color: rgb(26, 119, 189);
        line-height: 1.2;
        text-align: center;
        font-style: italic;
        margin-top: 20/19.2vw;
        margin-bottom: 20/19.2vw;
      }
    }
  }
  .kcdxt2 {
    margin: 0 auto;
    width: 1283/19.2vw;
    height: 855/19.2vw;
    background: url('../assets/kctx/kcdxt2.png');
    background-size: 100% 100%;
  }
  .videoParent {
    width: 100%;
    height: 723/19.2vw;
    position: relative;
    .videoBg1 {
      width: 100%;
      height: 621/19.2vw;
      background: url('../assets/kctx/video1.png');
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 102/19.2vw;
    }
    .videoBg2 {
      width: 100%;
      height: 199/19.2vw;
      background: url('../assets/kctx/video2.png');
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 363/19.2vw;
    }
    .video {
      width: 1170/19.2vw;
      height: 600/19.2vw;
      /* background: url('../assets/kctx/video3.png');
      background-size: 100% 100%; */
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .blueBlock1 {
    background: #0a7bd4;
    width: 12/19.2vw;
    height: 90/19.2vw;
    margin-right: 34/19.2vw;
  }
  .blueBlock2 {
    font-size: 40/19.2vw;
    font-family: 'Microsoft YaHei';
    color: rgb(20, 118, 190);
    line-height: 40/19.2vw;
    font-style: italic;
    font-weight: bold;
  }

  .jczs {
    width: 1275/19.2vw;
    margin: 0 auto;
    .jczsTitle {
      display: flex;
      align-items: center;
      margin-bottom: 60/19.2vw;
    }
    .jczsContent {
      width: 1180/19.2vw;
      height: 230/19.2vw;
      margin-left: 32/19.2vw;
      margin-bottom: 140/19.2vw;
      background: #fce3cb;
      box-shadow: 0 0 10px 10px rgba(255, 229, 207, 0.85);
      display: flex;
      justify-content: center;
      > div {
        text-align: center;
        img {
          margin: 0 auto;
          &:nth-child(1) {
            background-color: #c8cacb;
            width: 380/19.2vw;
            height: 230/19.2vw;
            margin: 0 10/19.2vw 40/19.2vw;
          }
          &:nth-child(2) {
            height: 30/19.2vw;
          }
        }
      }
    }
  }
  .bottomFont {
    font-size: 30/19.2vw;
    font-family: 'Microsoft YaHei';
    color: rgb(0, 126, 211);
    line-height: 1.2;
    text-align: center;
    font-style: italic;
    margin-top: 200/19.2vw;
    margin-bottom: 145/19.2vw;
  }
}
</style>
